<template>
  <p>Preis / m&sup2;: {{ this.qm_price }} € {{ this.qm_price_description }}</p>
  <input id="price_calc_window_size" type="range" min="2" max="5" v-model="window_size"  step="0.5" />
  <label for="price_calc_window_size">Fenstergröße: {{ this.window_size }} m&sup2;</label>
  <input id="price_calc_window_num" type="range" min="1" max="20" v-model="window_num"  step="1" />
  <label for="price_calc_window_num">Anzahl der Fenster: {{ this.window_num }}</label>
  <input id="price_calc_innenanstrich_qm" type="range" min="0" max="200" v-model="qm_size"  step="5" />
  <label for="price_calc_innenanstrich_qm">Fenstergröße: {{ this.qm_size }} m&sup2;</label>
  <p>Preis für Anstrich von {{ this.window_num }} x {{ this.window_size }} m&sup2; großen Fesntern: <strong>{{ this.totalprice }} €</strong> Netto inklusive aller Materialkosten und Anfahrten</p>
</template>

<script>

export default {
  name: 'PriceCalcLackierarbeiten',
  components: {

  },
  props: {
   qm_price: {
     type: Number,
     default: 12
   },
   qm_price_description: {
     type: String,
     default: "Gemeint ist die Fenstergröße"
   },
 },
  data() {
    return {
      qm_size: 70,
      window_size: 2.5,
      window_num: 5,
    }
  },
  computed: {
    totalprice() {
      // `this` points to the component instance
      return this.window_size * this.qm_price * this.window_num;
    }
  }
}
</script>

<template>
  <p>Preis / m&sup2;: {{ this.qm_price }} € {{ this.qm_price_description }}</p>
  <input id="price_calc_innenanstrich_qm" type="range" min="0" max="200" v-model="qm_size"  step="5" />
  <label for="price_calc_innenanstrich_qm">Wohnungsgröße: {{ this.qm_size }} m&sup2;</label>
  <p>Preis für einen Wohnunganstrich einer {{ this. qm_size }} m&sup2; Fassade: <strong>{{ this.totalprice }} €</strong> Netto inklusive aller Materialkosten und Anfahrten</p>
</template>

<script>

export default {
  name: 'PriceCalcFassade',
  components: {

  },
  props: {
   qm_price: {
     type: Number,
     default: 12
   },
   qm_price_description: {
     type: String,
     default: "Gemeint ist die Fassadenfläche"
   },
 },
  data() {
    return {
      qm_size: 120,
    }
  },
  computed: {
    totalprice() {
      // `this` points to the component instance
      return this.qm_size * this.qm_price;
    }
  }
}
</script>

import { createApp } from 'vue'
import PriceCalcWohnunganstrich from './PriceCalcWohnunganstrich.vue'
import PriceCalcFassade from './PriceCalcFassade.vue'
import PriceCalcLackierarbeiten from './PriceCalcLackierarbeiten.vue'
import PriceCalcVerputzarbeiten from './PriceCalcVerputzarbeiten.vue'
import PriceCalcSpachtelarbeiten from './PriceCalcSpachtelarbeiten.vue'


const mountEl = document.querySelector("#price_calc_innenanstrich");
if(mountEl != null) {
  createApp(PriceCalcWohnunganstrich, {
    qm_price: mountEl.dataset["qm_price"],
    qm_price_description: mountEl.dataset["qm_price_description"]
    }).mount('#price_calc_innenanstrich')
}

const mountEl2 = document.querySelector("#price_calc_fassade");
if(mountEl2 != null) {
  createApp(PriceCalcFassade, {
    qm_price: mountEl2.dataset["qm_price"],
    qm_price_description: mountEl2.dataset["qm_price_description"]
  }).mount('#price_calc_fassade')
}

const mountEl3 = document.querySelector("#price_calc_lackierarbeiten");
if(mountEl3 != null) {
  createApp(PriceCalcLackierarbeiten, {
    qm_price: mountEl3.dataset["qm_price"],
    qm_price_description: mountEl3.dataset["qm_price_description"]
  }).mount('#price_calc_lackierarbeiten')
}

const mountEl4 = document.querySelector("#price_calc_verputzarbeiten");
if(mountEl4 != null) {
  createApp(PriceCalcVerputzarbeiten, {
    qm_price_inside: mountEl4.dataset["qm_price_inside"],
    qm_price_outside: mountEl4.dataset["qm_price_outside"],
    qm_price_description: mountEl4.dataset["qm_price_description"]
  }).mount('#price_calc_verputzarbeiten')
}
  const mountEl5 = document.querySelector("#price_calc_spachtelarbeiten");
  if(mountEl5 != null) {
    createApp(PriceCalcSpachtelarbeiten, {
      qm_price_inside: mountEl5.dataset["qm_price"],
      qm_price_description: mountEl5.dataset["qm_price_description"]
    }).mount('#price_calc_spachtelarbeiten')
}

<template>
  <input type="radio" id="price_calc_innenanstrich_inside1" name="inside" :value="true" v-model="inside">
  <label for="price_calc_innenanstrich_inside1"><strong>Innenputz</strong> Preis / m&sup2;: {{ this.qm_price_inside }} € {{ this.qm_price_description }}</label>
  <input type="radio" id="price_calc_innenanstrich_inside2" name="inside" :value="false" v-model="inside">
  <label for="price_calc_innenanstrich_inside2"><strong>Außenputz</strong> Preis / m&sup2;: {{ this.qm_price_outside }} € {{ this.qm_price_description }}</label>
  <input id="price_calc_innenanstrich_qm" type="range" min="0" max="200" v-model="qm_size"  step="5" />
  <label for="price_calc_innenanstrich_qm">zu verputzende Fläche: {{ this.qm_size }} m&sup2;</label>
  <p>Preis für den <span v-if="inside">Innenputz</span> <span v-if="inside!=true">Außenputz</span> einer {{ this. qm_size }} m&sup2; Fläche: <strong>{{ this.totalprice }} €</strong> Netto inklusive aller Materialkosten und Anfahrten</p>
</template>

<script>

export default {
  name: 'PriceCalcVerputzarbeiten',
  components: {

  },
  props: {
   qm_price_inside: {
     type: Number,
     default: 10.50
   },
   qm_price_outside: {
     type: Number,
     default: 11.5
   },
   qm_price_description: {
     type: String,
     default: "Gemeint ist die zu verputzende Fläche"
   },
 },
  data() {
    return {
      inside: true,
      qm_size: 120,
    }
  },
  computed: {
    totalprice() {
      // `this` points to the component instance
      if(this.inside) {
        return this.qm_size * this.qm_price_inside;
      } else {
        return this.qm_size * this.qm_price_outside;
      }
    }
  }
}
</script>
